<template>
  <div class="work-card" @click="toggleShowWorkModal()">
    <img v-lazy="work.images.thumbnail" />
    <div>
      <h4>{{ work.title }}</h4>
      <p>
        {{ work.about }}
      </p>
      <ul>
        <li v-for="stack in work.stacks" :key="stack">{{ stack }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkCard",
  props: {
    work: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleShowWorkModal: function() {
      this.$emit("setSelectedWork", this.work);
      this.$store.commit("toggleShowWorkModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.work-card {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  /* margin: 30px; */
  display: flex;
  flex-direction: column;
  background-color: var(--light00);
  border-radius: 5px;
  width: 300px;
  max-height: 300px;
  cursor: pointer;
  overflow: hidden;
  img {
    height: 150px;
    width: 300px;
    object-fit: cover;
  }
  div {
    padding: 0 10px;

    h4 {
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    ul {
      margin: 0;
      padding: 20px 0;
      overflow: hidden;
    }
    li {
      display: inline;
      padding: 0 5px;
      margin: 0 3px;
      background-color: var(--color00);
      border-radius: 20px;
    }
  }
}
.work-card:hover {
  box-shadow: 0 0 20px var(--color07);
}
</style>
