<template>
  <div v-show="this.$store.getters.getShowWorkModal" class="work-modal">
    <div class="work-modal-overlay" @click="toggleShowWorkModal()"></div>
    <div class="work-modal-content" :class="{ 'load-content': loading }">
      <div v-if="loading" class="loading">
        <RotateSquare5 />
        <h6>Please wait</h6>
      </div>
      <div v-else class="content">
        <div class="title">
          <div>
            <h2>{{ selectedWork.title }}</h2>
          </div>
          <ul>
            <li>
              <a
                v-if="selectedWork.links.github"
                :href="selectedWork.links.github"
                rel="noreferrer noopener"
                target="_blank"
              >
                <feather type="github" size="16"></feather> Github
              </a>
            </li>
            <li>
              <a
                v-if="selectedWork.links.website"
                :href="selectedWork.links.website"
                rel="noreferrer noopener"
                target="_blank"
              >
                <feather type="external-link" size="16"></feather>
              </a>
            </li>
          </ul>
        </div>
        <div class="isi">
          <div class="carousel">
            <img :src="selectedWork.images.urls[0]" />
          </div>
          <div class="detail">
            <div class="d-item">
              <h4>About this project</h4>
              <p>
                {{ selectedWork.about }}
              </p>
            </div>
            <div class="d-item main-tech">
              <h4>Main tech stack</h4>
              <ul>
                <li v-for="stack in selectedWork.stacks" :key="stack">
                  {{ stack }}
                </li>
              </ul>
            </div>
            <div class="d-item library">
              <h4>Library used</h4>
              <ul>
                <li v-for="library in selectedWork.libraries" :key="library.id">
                  <a :href="library.repository">{{ library.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <feather type="x" size="36" @click="toggleShowWorkModal()"></feather>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
export default {
  name: "WorkModal",
  components: { RotateSquare5 },
  props: {
    selectedWork: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    toggleShowWorkModal: function() {
      this.$store.commit("toggleShowWorkModal");
    }
  }
};
</script>

<style lang="scss">
.work-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.work-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.close-button {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: var(--light00);
  color: var(--error);
  border-radius: 30%;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.load-content {
  justify-content: center;
  align-items: center;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    margin-top: 14px;
  }
}
.work-modal-content {
  z-index: 2;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  background-color: var(--light03);
  padding: 10px 0;
  display: flex;
  .isi {
    height: 100%;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .title {
    display: flex;
    flex-direction: column;
    div {
      background: var(--dark02);
      margin: 15px 0;
      h2 {
        color: white;
        margin: 0;
        padding-left: 5px;
        width: 100%;
        align-self: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Montserrat", sans-serif;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 5px;
        a {
          background-color: var(--color00);
          color: var(--dark00);
          box-shadow: none;
          outline: none;
          border: none;
          padding: 3px 7px;
          font-family: "Montserrat", sans-serif;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    .carousel {
      img {
        width: 100%;
        height: auto;
      }
    }
    .detail {
      background-color: var(--light00);
      margin: 10px;
      .d-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
          margin-bottom: 0;
          margin-top: 25 px;
          text-decoration: underline 7px solid var(--color00);
          border-bottom: 12px solid var(--color07);
          text-transform: uppercase;
        }
        p {
          margin: 0;
          padding: 10px 10px 20px 10px;
        }
      }

      .d-item.main-tech {
        ul {
          margin: 0;
          padding: 10px 0 20px 0;
          overflow: hidden;
        }
        li {
          display: inline;
          padding: 0 5px;
          margin: 0 3px;
          background-color: var(--color00);
          border-radius: 20px;
        }
      }
      .d-item.library {
        ul {
          list-style: none;
          padding: 10px 0 20px 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media (min-width: 769px) {
}
@media (min-width: 1024px) {
  .work-modal-content {
    .isi {
      overflow: hidden;
      display: flex;
      align-items: stretch;
      .carousel {
        background-color: var(--dark00);
        flex: 3;
        display: flex;
        align-items: center;
        border-radius: 0 0 0 10px;
      }
      .detail {
        margin: 0 10px 0 10px;
        border-radius: 0 0 10px 0;
        flex: 1;
        padding: 5px;
        overflow-y: scroll;
      }
    }
  }
  .close-button {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (min-width: 1216px) {
}
@media (min-width: 1408px) {
}
</style>
