<template>
  <div class="works">
    <work-modal v-if="selectedWork" :selected-work="selectedWork" />
    <div class="wrapper" :class="{ 'wrap-loading': !finishLoading }">
      <div class="title">
        <h2>Please have a look 😊</h2>
        <i>You can click a card for more detailed look</i>
      </div>
      <div v-if="!finishLoading" class="loading">
        <RotateSquare5 />
        <h6>Please wait</h6>
        <p v-if="timeout">This take longer than usual...</p>
      </div>
      <div v-if="finishLoading" class="grid-list">
        <work-card
          v-for="work in works"
          :key="work.id"
          :work="work"
          @setSelectedWork="setSelectedWork"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firestore";
import { RotateSquare5 } from "vue-loading-spinner";
import WorkCard from "@/components/WorkCard";
import WorkModal from "@/components/WorkModal";

const worksCollection = db.collection("works");

export default {
  name: "Works",
  components: { WorkCard, WorkModal, RotateSquare5 },
  data: function() {
    return {
      works: [],
      selectedWork: undefined,
      finishLoading: false,
      timeout: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.timeout = true;
    }, 3000);
    this.$bind("works", worksCollection).then(() => {
      this.finishLoading = true;
      this.timout = false;
    });
  },
  methods: {
    setSelectedWork: function(e) {
      this.selectedWork = e;
    }
  }
};
</script>

<style lang="scss" scoped>
.works {
  flex-grow: 1;
  padding: 0 2%;
  width: 100%;
  align-self: center;
  overflow-y: scroll;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  .wrapper {
    align-self: center;
    width: 80%;
    max-width: 1239px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    /* background-color: blue; */
    h2 {
      text-decoration: underline 8px solid var(--color07);
      margin-bottom: 5px;
    }
    i {
      color: #919191;
    }
  }
  .grid-list {
    /* background-color: green; */
    margin: 50px 0;
    width: 100%;
    padding-top: 20px;
    grid-gap: max(30px);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  .wrap-loading {
    height: 100%;
    .loading {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
@media (min-width: 769px) {
  .works {
    display: flex;
    flex-direction: column;
    align-items: center;

    .grid-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "title title";
    }
    .title {
      align-self: flex-start;
    }
  }
}
@media (min-width: 1216px) {
  .works {
    .grid-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "title title title";
    }
  }
}

@media (min-width: 750px) {
  .works {
    overflow-y: visible;
  }
}
</style>
